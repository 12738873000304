import React from "react"
import InnerHeader from "../../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/seo"
import * as rcifStyles from "./rcif-scholarship.module.scss"
import scholarshipPdf from '../../../../assets/images/about-us/rcif/rcif_scholarship_2024.pdf' 

const RCIFScholarship = ({ pageContext, location }) => {
  const pageTitle = "RCIF Scholarship"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />

      <div className={rcifStyles.wrapper}>
        {/* Hero Grid */}
        <section className={rcifStyles.heroGrid}>
          <div className={rcifStyles.copyAndImage}>
            {/* HERO IMAGE */}
            <div className={rcifStyles.heroImage}>
              <img
                src={
                  require("../../../../assets/images/about-us/rcif/rcif-circle-scholarship.png")
                    .default
                }
                alt=""
              />
            </div>
            {/* LOGO AND ANCHOR */}
            <div className={rcifStyles.copyAndAnchor}>
              <h2>2024 Scholarships</h2>
              <h3>ELIGIBILITY CRITERIA</h3>
              <ul>
                <li>
                  <p data-typesense-field="description">GPA of 3.0 or better</p>
                </li>
                <li>
                  <p data-typesense-field="description">Demonstrated good character in service, leadership, and dedication to your school and community</p>
                </li>
                <li>
                  <p data-typesense-field="description">Must be a senior at a surrounding high school<br/>(Nelsonville-York, Trimble, Logan, Federal Hocking, Athens or Alexander)</p>
                </li>
              </ul>
              <div>
                <a
                  href={scholarshipPdf}
                  className={rcifStyles.ctaScholarship}
                  title="Anchor to Download Scholarship Application PDF"
                  download
                >
                  Download Application
                </a>
                {/* store location template */}
                <form
                  name="rcif-scholarship-application-submission"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="special-request"
                  enctype="multipart/form-data"
                >
                  <input class="hidden" name="special-request" />
                  {/* hidden input for netlify */}
                  <input
                    type="hidden"
                    name="form-name"
                    value="rcif-scholarship-application-submission"
                  ></input>
                  <div className="form-group">
                    <label
                      htmlFor="rcif-scholarship-application"
                      className="file-upload__label__text"
                    >
                      Upload Application
                    </label>
                    <input
                      type="file"
                      className="file-upload"
                      id="rcif-scholarship-application"
                      name="rcif-scholarship-application"
                      placeholder="Upload Application"
                      required
                    />

                    <small>
                      Only valid and completed applications will be considered.
                    </small>
                  </div>
                  {/* submit button */}
                  <div>
                    <input className={rcifStyles.ctaScholarship} type="submit" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* END HERO GRID */}
      </div>
    </Layout>
  )
}

export default RCIFScholarship

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">RCIF Scholarship</title>
    <meta
      name="description"
      content="To provide impactful support to our local communities by awarding grants to build a clean, safe, and healthy community for our neighbors."
    />
  </>
)
